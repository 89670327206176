.newsletter {
    text-align: center;
    .stage {
        margin-bottom: 16px; }
    &__title {
        max-width: 650px;
        margin: 0 auto 32px;
        @include t {
            max-width: 550px; } }
    &__text {
        max-width: 450px;
        margin: 0 auto 48px;
        color: $neutrals4; }
    &__button {
        @include m {
            width: 100%;
            margin-bottom: 20px; }
        &:not(:last-child) {
            @include m; } }
    .subscription {
        max-width: 355px;
        margin: 0 auto 16px; }
    &__note {
        margin-top: 20px;
        @include caption-2;
        color: $neutrals5;
        @include m {
            max-width: 240px;
            margin: 0 auto; }
        a {
            font-weight: 600;
            color: $red;
            transition: opacity .2s;
            &:hover {
                opacity: .8; } } }
    &__btns {
        margin: 0 auto;
        display: flex;
        @include m {
          display: block; } } }
