.download {
	text-align: center;
	margin-top: 75px;
	&__center {
		@include m {
			padding: 0 12px; } }
	&__title {
		margin-bottom: 16px; }
	&__info {
		margin-bottom: 32px;
		color: $gray;
		@include d {
			max-width: 385px;
			margin: 0 auto 32px; }
		@include m {
			max-width: 250px;
			margin-bottom: 24px; }
		a {
			border-bottom: 1px solid transparent;
			color: $neutrals9;
			transition: border-color .2s;
			&:hover {
				border-color: $neutrals9; } }
  &__btns {
        display: flex;
        @include m {
            display: block; } }
  &__button {
        @include m {
            width: 100%; }
        &:not(:last-child) {
            margin-right: 16px;
            @include m {
                margin: 0 0 16px; } } } }
	&__bg {
		position: relative;
		left: 50%;
		width: 1020px;
		transform: translateX(-52%);
		margin-top: 120px;
		@include d {
			width: 850px;
			margin-bottom: 72px; }
		@include t {
			width: 750px; }
		@include m {
			width: 550px;
			transform: translateX(-51.5%); } }
	&__preview {
		&:nth-child(2) {
			top: -11%;
			left: 53%;
			width: 24%;
			transform: translateX(-50%); }
		&:nth-child(3) {
			top: 30.5%;
			right: 3%;
			width: 47%; }
		@include nf {
			position: absolute; }
		img {
			width: 100%; } }
	&__subtitle {
		margin-bottom: 70px;
		@include d {
			max-width: 390px;
			margin: 0 auto 64px; }
		@include m {
			max-width: 280px;
			margin-bottom: 44px;
			font-size: 32px; } }
	&__list {
		display: flex;
		@include d {
			flex-wrap: wrap;
			justify-content: center;
			margin-top: -80px; }
		@include m {
			margin-top: -56px; } }
	&__item {
		position: relative;
		flex: 0 0 20%;
		width: 20%;
		padding: 68px 32px 64px;
		color: $black;
		@include d {
			flex: 0 0 33.333%;
			width: 33.333%;
			margin-top: 80px;
			padding: 0 24px; }
		@include m {
			flex: 0 0 50%;
			width: 50%;
			margin-top: 56px;
			padding: 0 12px; }
		&:before {
			content: "";
			position: absolute;
			top: -5px;
			right: -5px;
			left: -5px;
			bottom: -5px;
			z-index: 1;
			background: #F7F7F8;
			box-shadow: 0px 8px 32px rgba(0, 0, 0, 0.1);
			border-radius: 24px;
			opacity: 0;
			transition: opacity .2s;
			@include d {
				display: none; } }
		&:hover:before {
			opacity: 1; }
		&:nth-child(3):after {
			@include d {
				display: none; }
			@include m {
				display: block; } }
		&:nth-child(2n):after {
			@include m {
				display: none; } }
		@include nl {
			&:after {
				content: "";
				position: absolute;
				top: 6px;
				right: -2px;
				bottom: 6px;
				width: 4px;
				background: rgba($neutrals6, .15);
				@include t {
					top: 0;
					bottom: 0; } } } }
	&__icon,
	&__category,
	&__text {
		position: relative;
		z-index: 3; }
	&__icon {
		display: flex;
		justify-content: center;
		align-items: center;
		height: 32px;
		margin-bottom: 24px;
		font-size: 0; }
	&__category {
		margin-bottom: 4px; }
	&__text {
		color: $gray; }
	@include nl {
		margin-bottom: 141px;
		@include d {
			margin-bottom: 96px; }
		@include m {
			margin-bottom: 48px; } } }
