.exchanges {
    text-align: center;
    .stage {
        text-align: center; }
    &__title {
        margin: 0 auto 32px;
        text-align: center;
        @include m {
            text-align: center; } }
    &__text {
        max-width: 450px;
        margin: 0 auto 48px;
        color: $neutrals4; }
    &__list {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -16px;
        @include t {
            display: block;
            margin: 0;
            padding: 48px 0; }
        @include m {
            padding: 0;
            border: none; }
        @include dark; }
    &__item {
        display: flex;
        align-items: center;
        flex: 0 0 calc(32% - 32px);
        width: calc(32% - 32px);
        margin: 80px 16px -1px;
        padding-bottom: 48px;
        cursor: pointer;
        @include x {
            cursor: default; }
        @include t {
            width: 100%;
            margin: 0;
            padding: 0;
            border: none; }
        @include m {
            display: block;
            text-align: center; }
        @include dark {
            border-color: $neutrals3; }
        &:not(:last-child) {
            @include t {
                margin-bottom: 48px; }
            @include m {
                margin-bottom: 40px; } }
        .exchanges__button {
            position: absolute;
            top: calc(100% + 24px);
            left: 50%;
            margin-left: -40px;
            text-align: center;
            visibility: hidden;
            opacity: 0;
            @include x {
                position: static;
                margin-top: 24px;
                visibility: visible;
                opacity: 1; }
            @include m {
                margin-top: 16px;
                margin-left: 0px;
                padding: 24px;
                font-weight: 700;
                font-size: 16px; } }
        &:hover {
            .exchanges__details {
                transform: translateY(-33px);
                @include x {
                    transform: translateY(0); } }
            .exchanges__button {
                visibility: visible;
                opacity: 1; } } }
    &__preview {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-shrink: 0;
        width: 160px;
        height: 160px;
        margin-right: 32px;
        border-radius: 24px;
        -webkit-box-shadow: 0 3px 20px rgba(33,39,39,0.06);
        @include m {
            margin: 0 auto 16px; }
        img {
            max-width: 100px; } }
    &__details {
        position: relative;
        transition: transform .2s;
        min-width: 130px; }
    &__status {
        margin-bottom: 12px;
        @include m {
            line-height: 22px; } }
    &__subtitle {
        margin-bottom: 4px;
        @include body-2; }
    &__description {
        @include caption-1;
        color: $neutrals4; }
    &__btns {
        margin-top: 80px;
        text-align: center;
        @include t {
            margin-top: 64px; }
        @include m {
            margin-top: 48px; } }
    &__button {
        @include m {
            width: 100%; }
        &:not(:last-child) {
            margin-right: 16px;
            margin-bottom: 50px; }
        @include m {
            margin: 0 0 16px; } } }
