[class^="section"] {
    padding: 136px 0;
    @include d {
        padding: 112px 0; }
    @include m {
        padding: 64px 0; } }

[class^="section-aboutmarketplace"] {
    padding: 0px;
    @include d {
        padding: 112px 0; }
    @include m {
        padding: 64px 0; } }

[class^="section-platform"] {
    padding: 64px 0;
    @include d {
        padding: 112px 0; }
    @include m {
        padding: 64px 0; } }

[class^="section-benefits"] {
    padding: 0px 0 50px;
    @include d {
        padding: 0 0 64px; }
    @include m {
        padding: 20px 0; } }

[class^="section-marketplaceintro"] {
    padding: 0 0 100px;
    @include d {
        padding: 0 0 64px; }
    @include m {
        padding: 20px 0; } }

[class^="section-coinintro"] {
    padding: 0 0 100px;
    @include d {
        padding: 0 0 64px; }
    @include m {
        padding: 20px 0; } }

[class^="section-aboutparticl"] {
    padding: 0 0 100px;
    @include d {
        padding: 0 0 64px; }
    @include m {
        padding: 20px 0; } }

[class^="section-believe"] {
    padding: 0 0 164px;
    @include d {
        padding: 0 0 64px; }
    @include m {
        padding: 20px 0; } }

[class^="section-asseenon"] {
    padding: 0 0 164px;
    @include d {
        padding: 0 0 64px; }
    @include m {
        padding: 20px 0; } }

[class^="section-more"] {
    padding: 0 0 164px;
    @include d {
        padding: 0 0 64px; }
    @include m {
        padding: 20px 0; } }

[class^="section-academy"] {
    padding: 0 0 164px;
    @include d {
        padding: 0 0 64px; }
    @include m {
        padding: 20px 0; } }

.section-bg {
    background: $neutrals7;
    @include dark {
        background: $neutrals2; } }

.section-border-top {
    border-top: 1px solid $neutrals6;
    @include dark {
        border-color: $neutrals3; } }

.section-border-bottom {
    border-bottom: 1px solid $neutrals6;
    @include dark {
        border-color: $neutrals3; } }

.section-pb {
    padding-top: 0;
    @include d {
        padding-top: 0; }
    @include m {
        padding-top: 0; } }

.section-pb64 {
    padding: 0 0 64px;
    @include d {
        padding: 0 0 64px; } }
