.academy {
    &__head {
        max-width: 600px;
        margin: 0 auto 80px;
        text-align: center;
        @include d {
            margin-bottom: 64px; }
        @include m {
            text-align: center; } }
    &__btns {
        margin-top: 40px;
        text-align: center; }
    &__button {
        @include m {
            padding: 0 22px; }
        &:not(:last-child) {} }
    &__stage {
        margin-bottom: 8px;
        color: $neutrals4; }
    &__list {
        display: flex;
        @include m {
            display: block;
            margin: 0 auto; } }
    &__checkpoints {
        li {
            position: relative;
            padding-left: 36px;
            font-weight: 600;
            &:before {
                content: "";
                position: absolute;
                top: 5px;
                left: 0;
                width: 10px;
                height: 10px;
                background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='16' width='16' viewBox='0 0 16 16'%3E%3Cg fill='%23754CED' %3E%3Ccircle cx='8' cy='8' r='8' fill='%23754CED'%3E%3C/circle%3E%3C/g%3E%3C/svg%3E") no-repeat 50% 50% / 100% auto; }
            &:not(:last-child) {
                margin-bottom: 10px; } }
        a {
                font-weight: 600;
                color: $neutrals4;
                font-size: 14px;
                transition: opacity .2s;
                &:hover {
                  color: $neutrals2; } } }

    &__item {
        position: relative;
        flex: 0 0 calc(25% - 11px);
        width: calc(25% - 11px);
        margin: 0 16px;
        text-align: left;
        padding: 25px;
        @include t {
         flex: 0 0 350px;
         width: 350px;
         margin: 0; }
        @include m {
         text-align: left;
         display: block;
         max-width: 350px;
         margin: 50px 0 0 0; }
        &:first-child {}
        &:nth-child(2) {
            border-width: 0 0px;
            border-style: solid;
            border-right: none;
            border-color: $neutrals6;
            @include m {
                border: none; }
            @include dark {
                border-color: $neutrals3; } }
        &:nth-child(3) {
            border-width: 0 0px;
            border-style: solid;
            border-color: $neutrals6;
            @include m {
                border: none; }
            @include dark {
                border-color: $neutrals3; } }
        &:last-child {}
        &:not(:last-child) {
            @include m {
                margin-bottom: 24px;
                padding-bottom: 24px;
                border-bottom: 1px solid $neutrals6;
                @include dark {
                    border-color: $neutrals3; } } } }

    &__item-last {
        flex: 0 0 31%;
        padding: 34px 83px;
        @include x {
            padding: 34px 64px; }
        @include d {
            padding: 32px; }
        @include m {
            padding: 0; }
        &:first-child {
            padding-left: 0; }
        &:nth-child(2) {
            flex: 0 0 38%;
            border-width: 0 1px;
            border-style: solid;
            border-color: $neutrals6;
            @include m {
                border: none; }
            @include dark {
                border-color: $neutrals3; } }
        &:last-child {
            padding-right: 0;
            @include m {
                padding-bottom: 24px;
                border-bottom: 1px solid $neutrals6;
                @include dark {
                    border-color: $neutrals3; } } } }
    &__icon {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 48px;
        height: 48px;
        margin-bottom: 20px;
        path {
            stroke: $neutrals2;
            @include dark {
                stroke: $white; } }
        circle {
            stroke: $neutrals2;
            @include dark {
                stroke: $white; } }
        rect {
            stroke: $neutrals2;
            @include dark {
                stroke: $white; } }
        polygon {
            stroke: $neutrals2;
            @include dark {
                stroke: $white; } }
        polyline {
            stroke: $neutrals2;
            @include dark {
                stroke: $white; } }
        line {
            stroke: $neutrals2;
            @include dark {
                stroke: $white; } }
        ellipse {
            stroke: $neutrals2;
            @include dark {
                stroke: $white; } } }
    &__category {
        margin-bottom: 32px;
        font-weight: 600; }
    &__content {
        @include caption-1;
        color: $neutrals4; } }
