[data-aos="animation-opacity"] {
  opacity: 0;
  transition-property: opacity;
  &.aos-animate {
    opacity: 1; } }

[data-aos="animation-scale-left-top"] {
	transform: translate(-70px,40px) scaleY(1.3);
	opacity: 0;
	transition-property: transform, opacity;
	&.aos-animate {
		transform: translate(0) scaleY(1);
		opacity: 1; } }

[data-aos="animation-scale-top"] {
	transform: translateY(20px) scaleY(1.2);
	opacity: 0;
	transition-property: transform, opacity;
	&.aos-animate {
		transform: translate(0) scaleY(1);
		opacity: 1; } }

[data-aos="animation-translate-up"] {
	transform: translateY(30px);
	opacity: 0;
	transition-property: transform, opacity;
	&.aos-animate {
		transform: translate(0);
		opacity: 1; } }

[data-aos="animation-translate-down"] {
	transform: translateY(-30px);
	opacity: 0;
	transition-property: transform, opacity;
	&.aos-animate {
		transform: translate(0);
		opacity: 1; } }

@keyframes up-down {
	50% {
		transform: translateY(-30px); } }
