// media queries
@mixin w {
	@media only screen and (max-width: "1419px") {
		@content; } }
@mixin x {
	@media only screen and (max-width: "1339px") {
		@content; } }
@mixin d {
	@media only screen and (max-width: "1179px") {
		@content; } }
@mixin t {
	@media only screen and (max-width: "1023px") {
		@content; } }
@mixin m {
	@media only screen and (max-width: "767px") {
		@content; } }
@mixin a {
	@media only screen and (max-width: "639px") {
		@content; } }
@mixin s {
	@media only screen and (max-width: "474px") {
		@content; } }

@font-face {
  font-family: 'SFProDisplay-Bold';
  src: url('../fonts/SFProDisplay-Bold.woff') format('woff'), url('../fonts/fonts/SFProDisplay-Bold.woff2') format('woff2');
  font-weight: 700;
  font-style: normal; }

// fonts
@mixin poppins {
  font-family: 'Poppins', sans-serif; }

@mixin sf-pro-display {
  font-family: 'SFProDisplay-Bold'; }

@mixin fi {
	font-family: 'Inter', sans-serif; }
@mixin fp {
	font-family: 'Poppins', sans-serif; }

// colors
$white: #ffffff;
$red: #754CED;  //purple
$purple: #9757D7;
$purple-light: #CDB4DB;
$pink: #EF466F;
$green: #45B26B;
$blue: #3772FF;
$asphalt: #E4D7CF;
$yellow: #FFD166;

$dark: #1E252B;
$dark-bg: #2C353D;
$dark-light: #74777E;
$black: #272E35;

$neutrals1: #141416;
$neutrals2: #23262F;
$neutrals3: #353945;
$neutrals4: #777E90;
$neutrals5: #B1B5C3;
$neutrals6: #E6E8EC;
$neutrals7: #F4F5F6;
$neutrals8: #FCFCFD;
$neutrals9: #754CED; //purple

$neutrals10: #6A7986;

// typography

@mixin d1 {
	@include fp;
	font-size: 72px;
	line-height: (80/72);
	font-weight: 600; }

@mixin body-1 {
  font-size: 24px;
  line-height: (32/24);
  letter-spacing: -.01em; }

@mixin body-2 {
  font-size: 24px;
  line-height: (32/24);
  font-weight: 600; }

@mixin caption-1 {
  font-size: 14px;
  line-height: (24/14); }

@mixin caption-2 {
  font-size: 12px;
  line-height: (20/12); }

@mixin hairline-1 {
  line-height: 1;
  font-weight: 700;
  text-transform: uppercase; }

@mixin hairline-2 {
  font-size: 12px;
  line-height: 1;
  font-weight: 700;
  text-transform: uppercase; }

@mixin button-1 {
  @include sf-pro-display;
  font-size: 16px;
  font-weight: 700;
  line-height: 1; }

@mixin button-2 {
  @include sf-pro-display;
  font-size: 14px;
  line-height: (16/14);
  font-weight: 700; }
