.team-intro {
    text-align: center;
    padding-bottom: 0px;
    .stage {
        margin-bottom: 16px;
        color: $red; }
    &__title {
        max-width: 650px;
        margin: 0 auto 32px;
        @include t {
            max-width: 550px; } }
    &__text {
        max-width: 700px;
        margin: 0 auto;
        color: $neutrals4; }
    &__btns {
        margin-top: 15px;
        margin-bottom: 15px;
        text-align: center;
        @include t {
            margin-top: 64px; }
        @include m {
            margin-top: 48px; } }
    &__button {
        @include m {
            width: 100%; }
        &:not(:last-child) {
            margin-right: 16px;
            margin-bottom: 50px; }
        @include m {
            margin: 0 0 16px; } }
    &__note {
        @include caption-2;
        color: $neutrals5;
        @include m {
            max-width: 240px;
            margin: 0 auto; }
        a {
            font-weight: 600;
            color: $red;
            transition: opacity .2s;
            &:hover {
                opacity: .8; } } } }
