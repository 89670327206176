.learn {
    text-align: center;
    .stage {
        margin-bottom: 16px; }
    &__title {
        max-width: 650px;
        margin: 0 auto 32px;
        @include t {
            max-width: 550px; } }
    &__text {
        max-width: 450px;
        margin: 0 auto 48px;
        color: $neutrals4; }
    &__button {
        @include m {
            width: 100%; }
        &:not(:last-child) {
            margin-right: 16px;
            @include m {
                margin: 0 0 16px; } } } }
