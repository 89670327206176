.say-hello {
  position: relative;
  height: 1963px;
  margin-bottom: 0px;
  padding-top: 112px;
  overflow: hidden;
  @include d {
    margin-bottom: 40px; }
  @include t {
    height: 1500px;
    padding-top: 90px; }
  @include m {
    height: 1115px;
    margin-bottom: 0;
    padding-top: 72px; }
  &__wrap {
    position: relative;
    z-index: 3;
    max-width: 600px;
    @include m {
      max-width: 100%;
      text-align: center; } }
  &__stage {
    margin-bottom: 24px;
    color: $purple;
    @include m {
      margin-bottom: 16px; } }
  &__title {
    margin-bottom: 48px;
    @include t {
      margin-bottom: 32px; } }
  &__text2 {
    padding-top: 15px;
    font-weight: bold;
    color: $neutrals4;
    &:hover {
        color: $neutrals2; } }
  &__info {
    max-width: 410px;
    margin-bottom: 45px;
    @include m {
      margin: 0; } }
  &__btn .btn {
    min-width: 233px;
    height: 82px;
    @include m {
      display: none; } }
  &__bg,
  &__circle,
  &__list {
    position: absolute;
    font-size: 0; }
  &__bg,
  &__circle {
    z-index: 1; }
  &__bg {
    top: 112px;
    right: calc(50% - 904px);
    @include d {
      right: calc(50% - 950px); }
    @include m {
      top: 382px;
      right: -140px;
      width: 411px; } }
  &__bg &__pic {
    width: 100%; }
  &__item2 {
    display: flex;
    align-items: center; }
  &__icon2 {
    flex-shrink: 0;
    width: 25px;
    margin-right: 24px;
    font-size: 0;
    padding-top: 15px; }
  &__pic2 {
    width: 100%; }
  &__circle {
    border-radius: 50%;
    &:first-child {
      top: 207px;
      left: calc(50% - 814px);
      width: 191px;
      height: 191px;
      background: $purple-light; }
    &:nth-child(2) {
      top: 642px;
      left: calc(50% - 560px);
      width: 80px;
      height: 80px;
      background: $white;
      @include d {
        left: 32px; }
      @include t {
        top: 550px; } } }
  &__list {
    top: 454px;
    left: 50%;
    transform: translateX(-35.6%) rotate(-30deg);
    z-index: 2;
    @include m {
      top: 350px; } }
  &__preview {
    font-size: 0;
    @include m {
      width: 1000px; }
    &:first-child {
      @include t {
        width: 1400px; }
      @include m {
        width: 1000px; } }
    &:nth-child(2) {
      position: relative;
      z-index: 2;
      margin-top: -220px;
      @include t {
        margin-top: -150px;
        width: 1160px; }
      @include m {
        width: 830px;
        margin-top: -105px; } } }
  &__preview &__pic {
    @include t {
      width: 100%; } } }
